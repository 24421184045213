import { VuexModule, Module, Action, Mutation } from "vuex-module-decorators";
import AppointmentApi from "@/api/appointment-api";
import {
  formatDate,
  formatDateCalendar,
  addOpacity,
  getOptimalTextColor,
} from "../../helpers/data";
import {
  installationsModule,
  commonModule,
} from "@/store/modules/store-accessor";
import _ from "lodash";
import dayjs from "dayjs";
import i18n from "@/i18n";

@Module({ namespaced: true, name: "appointments" })
export default class AppointmentsModule extends VuexModule {
  public appointments: AppointmentDto[] = [];
  public calendarAppointments: CalendarAppointmentsTasksDto = {
    appointments: [],
    tasks: [],
  };
  public isLoading = true;
  public isLoadingIndicator = true;
  public filters = `?date_from=${dayjs()
    .set("hour", 0)
    .set("minute", 0)
    .set("second", 0)
    .set("millisecond", 0)
    .toISOString()}&date_to=${dayjs()
    .set("hour", 23)
    .set("minute", 59)
    .set("second", 59)
    .set("millisecond", 999)
    .toISOString()}`; // Default filter for today
  public categories: CategoriesDto = { id: [], name: [] };
  public queryObject: any = {};
  public calendarSelectedType: string;
  public calendarEndDate: any;
  public isLoadingTable = false;
  public appointmentsFilters = ``;
  public search = "";
  public addCalendarInstallation: string | null = null;

  @Action({ commit: "setAppointments" })
  async getAppointmentsAction(query: string): Promise<AppointmentDto[]> {
    this.setIsLoadingTable(true);
    const appointmentApi = new AppointmentApi();
    const appointments = await appointmentApi.getAllAppointments(query);
    this.setIsLoadingTable(false);
    return appointments;
  }

  @Action({ commit: "setCalendarAppointments" })
  async getCalendarAppointmentsAction(
    query: string
  ): Promise<CalendarAppointmentsTasksDto> {
    const appointmentApi = new AppointmentApi();
    this.setIsLoading(true);
    const calendarAppointments = await appointmentApi.getCalendarAppointments(
      query
    );
    this.setIsLoading(false);
    return calendarAppointments;
  }

  @Action
  async getCalendarAppointmentsExportAction(data: any) {
    const appointmentApi = new AppointmentApi();
    const response = await appointmentApi.getCalendarAppointmentsExport(data);
    const blob = new Blob([response], { type: "application/zip" });
    return blob;
  }

  @Action
  async createAppointment(data: Record<string, any>) {
    const appointment_data = _.cloneDeep(data);

    appointment_data.assignee_id = appointment_data.assignee_id.id;
    appointment_data.type_id = appointment_data.type_id.id;
    appointment_data.duration = appointment_data.duration.dur;
    appointment_data.scheduled_start = appointment_data.scheduled_start_time
      ? dayjs(
          appointment_data.scheduled_start +
            " " +
            appointment_data.scheduled_start_time
        )
      : null;
    delete appointment_data.scheduled_start_time;

    if (
      appointment_data.installation_item_id &&
      appointment_data.installation_item_id.id
    ) {
      appointment_data.item_type_text =
        appointment_data.installation_item_id.item_type_text;
      appointment_data.item_make =
        appointment_data.installation_item_id.item_make;
      appointment_data.item_model =
        appointment_data.installation_item_id.item_model;
      appointment_data.item_serial_no =
        appointment_data.installation_item_id.item_serial_no;
      appointment_data.installation_item_id =
        appointment_data.installation_item_id.id;
    } else {
      delete appointment_data.installation_item_id;
    }
    const appointmentApi = new AppointmentApi();
    await appointmentApi.createAppointment(appointment_data);
    await installationsModule.getInstallationInfo(
      appointment_data.installation_id
    );
    this.setIsLoading(false);
  }

  @Action
  async editAppointment(data: Record<string, any>) {
    const dataPayload = _.cloneDeep(data);
    if (data.assignee) {
      dataPayload.assignee_id = data.assignee.id;
    } else {
      delete dataPayload.assignee_id;
    }
    dataPayload.type_id = data.type_id.id;

    if (data.scheduled_start) {
      dataPayload.scheduled_start = dayjs(
        data.scheduled_start + " " + data.scheduled_start_time
      );
      dataPayload.duration = data.duration.dur;
    } else {
      delete dataPayload.scheduled_start;
      delete dataPayload.duration;
      delete dataPayload.scheduled_start_time;
    }
    if (data.installation_item_id && data.installation_item_id.id) {
      dataPayload.item_type_text =
        dataPayload.installation_item_id.item_type_text;
      dataPayload.item_make = dataPayload.installation_item_id.item_make;
      dataPayload.item_model = dataPayload.installation_item_id.item_model;
      dataPayload.item_serial_no =
        dataPayload.installation_item_id.item_serial_no;
      dataPayload.installation_item_id = data.installation_item_id.id;
    } else {
      delete dataPayload.installation_item_id;
    }
    dataPayload.is_confirmed = data.appointment_is_confirmed;
    dataPayload.notes = data.appointment_notes;
    dataPayload.is_confirmed = dataPayload.appointment_is_confirmed;
    dataPayload.notes = dataPayload.appointment_notes;

    delete dataPayload.appointment_notes;
    delete dataPayload.appointment_is_confirmed;
    delete dataPayload.appointment_status_id;

    const appointmentApi = new AppointmentApi();
    await appointmentApi.updateAppointment(dataPayload.id, dataPayload);
    await installationsModule.getInstallationInfo(dataPayload.installation_id);
    this.setIsLoading(false);
  }

  @Action
  async deleteAppointment(data: Record<string, any>) {
    const appointmentApi = new AppointmentApi();
    await appointmentApi.deleteAppointment(data.id);
    await installationsModule.getInstallationInfo(data.installation_id);
    this.setIsLoading(false);
  }

  @Action
  async cancelAppointment(data: Record<string, any>) {
    const appointmentApi = new AppointmentApi();
    await appointmentApi.cancelAppointment(data.id);
    await installationsModule.getInstallationInfo(data.installation_id);
    this.setIsLoading(false);
  }

  @Action
  async endAppointment(data: Record<string, any>) {
    const jobData = _.cloneDeep(data);

    jobData.assignee_id = jobData.assignee_id.id;
    jobData.actions = jobData.type_id;
    jobData.payment_type_id = jobData.payment_type?.id;
    jobData.client_paid = jobData.radioValue == "user" ? true : false;
    if (!jobData.is_paid) {
      delete jobData.payment_amount;
      delete jobData.payment_type_id;
    }
    delete jobData.is_paid;
    delete jobData.radioValue;
    delete jobData.type_id;
    delete jobData.payment_type;

    jobData.actions.map((item: Record<string, any>) => {
      item.unit = item.is_time_charged ? item.unit.unit : null;
    });

    if(jobData.materials && jobData.materials.length > 0) {
      jobData.materials.map((item: Record<string, any>) => {
        item.unit = item.is_unit_based ? item.unit : null;
      });
    }

    const appointmentApi = new AppointmentApi();

    await appointmentApi.endAppointment(jobData.id, jobData);
    await installationsModule.getInstallationInfo(jobData.installation_id);
    this.setIsLoading(false);
  }

  @Action({ commit: "setCategories" })
  async getCategories() {
    let categories = {};
    if (localStorage.getItem("calendar_type") == "category") {
      categories = {
        name: commonModule.techniciansCombo.map((item: any) => item.name),
        id: commonModule.techniciansCombo.map((item: any) => item.id),
      };
    } else {
      categories = {
        name: commonModule.personnelCombo.map((item: any) => item.name),
        id: commonModule.personnelCombo.map((item: any) => item.id),
      };
    }
    return categories;
  }

  @Action
  async getAppointmentsPdfExportAction({
    query,
    data,
  }: {
    query: string;
    data: Record<string, any>;
  }) {
    const appointmentsApi = new AppointmentApi();
    const response = await appointmentsApi.getAppointmentsPdfExport(
      query,
      data
    );
    const blob = new Blob([response], { type: "application/pdf" });
    return blob;
  }

  @Mutation
  public setAppointments(appointments: AppointmentDto[]): void {
    this.appointments = appointments;
  }

  @Mutation
  public clearAppointments(): void {
    this.appointments = [];
  }

  @Mutation
  public setCalendarAppointments(
    calendarAppointments: CalendarAppointmentsTasksDto
  ): void {
    const events = [];
    for (let i = 0; i < calendarAppointments.appointments.length; i++) {
      const startDateTimeformatted = formatDateCalendar(
        calendarAppointments.appointments[i].scheduled_start
      );
      const endDateTimeformatted = formatDateCalendar(
        calendarAppointments.appointments[i].scheduled_end
      );

      const startDatetime = new Date(startDateTimeformatted);
      let endDatetime = new Date(endDateTimeformatted);
      const realEndDateTime = new Date(endDateTimeformatted);
      const realStartDateTime = new Date(startDateTimeformatted);

      if (this.calendarSelectedType === "month") {
        if (endDatetime > this.calendarEndDate) {
          endDatetime = this.calendarEndDate;
        }
      } else {
        if (endDatetime.getDate() > startDatetime.getDate()) {
          const nextDayStartDateTime = new Date(realEndDateTime);
          nextDayStartDateTime.setHours(0, 0, 0, 0);

          //split the event to current day and the next day because v-calendar doesnt render it correctly if it spans to multiple days on the Week & Day Calendar. Month works fine
          events.push({
            id: calendarAppointments.appointments[i].id,
            name: calendarAppointments.appointments[i].type_name,
            start: nextDayStartDateTime,
            end: endDatetime,
            color:
              calendarAppointments.appointments[i].appointment_status_id == 4
                ? addOpacity(
                    calendarAppointments.appointments[i].type_color,
                    0.3
                  )
                : calendarAppointments.appointments[i].appointment_status_id ==
                  1
                ? addOpacity(
                    calendarAppointments.appointments[i].type_color,
                    0.5
                  )
                : calendarAppointments.appointments[i].type_color,
            textColor: getOptimalTextColor(
              calendarAppointments.appointments[i].type_color!
            ),
            details:
              ("0" + startDatetime.getHours()).slice(-2) +
              ":" +
              ("0" + startDatetime.getMinutes()).slice(-2) +
              " - " +
              ("0" + realEndDateTime.getHours()).slice(-2) +
              ":" +
              ("0" + realEndDateTime.getMinutes()).slice(-2),
            user: calendarAppointments.appointments[i].assignee_id,
            notes: calendarAppointments.appointments[i].notes,
            address:
              calendarAppointments.appointments[i].address?.split(",")[0],
            client_name: calendarAppointments.appointments[i].client_name,
            tag_id: calendarAppointments.appointments[i].tag_id
              ?.split("_")
              .pop(),
            area: calendarAppointments.appointments[i].area,
            installation_id:
              calendarAppointments.appointments[i].installation_id,
            timed: true,
            assignee_id: calendarAppointments.appointments[i].assignee_id,
            assignee_ids: calendarAppointments.appointments[i].assignee_ids,
            assignees: calendarAppointments.appointments[i].assignees,
            status_id:
              calendarAppointments.appointments[i].appointment_status_id,
            category: calendarAppointments.appointments[i].assignee_id,
            is_business: calendarAppointments.appointments[i].is_business,
            entity_name: calendarAppointments.appointments[i].entity_name,
            installation_item_id:
              calendarAppointments.appointments[i].installation_item_id,
            job_id: calendarAppointments.appointments[i].job_id,
            action_type_id: calendarAppointments.appointments[i].action_type_id,
            appointment_status_id:
              calendarAppointments.appointments[i].appointment_status_id,
            realEndDateTime: realEndDateTime,
            realStartDateTime: realStartDateTime,
            job_item: calendarAppointments.appointments[i].job_item,
            item_type_text: calendarAppointments.appointments[i].item_type_text,
            item_make: calendarAppointments.appointments[i].item_make,
            item_model: calendarAppointments.appointments[i].item_model,
            item_serial_no: calendarAppointments.appointments[i].item_serial_no,
            installation_description: calendarAppointments.appointments[i].installation_description,
            contacts: calendarAppointments.appointments[i].client_contacts
          });

          endDatetime = new Date(startDatetime);
          endDatetime.setHours(23, 59, 59, 999);
        }
      }

      events.push({
        id: calendarAppointments.appointments[i].id,
        name: calendarAppointments.appointments[i].type_name,
        start: startDatetime,
        end: endDatetime,
        color:
          calendarAppointments.appointments[i].appointment_status_id == 4
            ? addOpacity(calendarAppointments.appointments[i].type_color, 0.3)
            : calendarAppointments.appointments[i].appointment_status_id == 1
            ? addOpacity(calendarAppointments.appointments[i].type_color, 0.5)
            : calendarAppointments.appointments[i].type_color,
        textColor: getOptimalTextColor(
          calendarAppointments.appointments[i].type_color!
        ),
        details:
          ("0" + startDatetime.getHours()).slice(-2) +
          ":" +
          ("0" + startDatetime.getMinutes()).slice(-2) +
          " - " +
          ("0" + realEndDateTime.getHours()).slice(-2) +
          ":" +
          ("0" + realEndDateTime.getMinutes()).slice(-2),
        user: calendarAppointments.appointments[i].assignee_id,
        notes: calendarAppointments.appointments[i].notes,
        address: calendarAppointments.appointments[i].address?.split(",")[0],
        client_name: calendarAppointments.appointments[i].client_name,
        tag_id: calendarAppointments.appointments[i].tag_id?.split("_").pop(),
        area: calendarAppointments.appointments[i].area,
        installation_id: calendarAppointments.appointments[i].installation_id,
        timed: true,
        assignee_id: calendarAppointments.appointments[i].assignee_id,
        assignee_ids: calendarAppointments.appointments[i].assignee_ids,
        assignees: calendarAppointments.appointments[i].assignees,
        status_id: calendarAppointments.appointments[i].appointment_status_id,
        category: calendarAppointments.appointments[i].assignee_id,
        is_business: calendarAppointments.appointments[i].is_business,
        entity_name: calendarAppointments.appointments[i].entity_name,
        installation_item_id:
          calendarAppointments.appointments[i].installation_item_id,
        job_id: calendarAppointments.appointments[i].job_id,
        realEndDateTime: realEndDateTime,
        realStartDateTime: realStartDateTime,
        description: calendarAppointments.appointments[i].description,
        job_item: calendarAppointments.appointments[i].job_item,
        item_type_text: calendarAppointments.appointments[i].item_type_text,
        item_make: calendarAppointments.appointments[i].item_make,
        item_model: calendarAppointments.appointments[i].item_model,
        item_serial_no: calendarAppointments.appointments[i].item_serial_no,
        action_type_id: calendarAppointments.appointments[i].action_type_id,
        appointment_status_id:
          calendarAppointments.appointments[i].appointment_status_id,
        installation_description: calendarAppointments.appointments[i].installation_description,
        contacts: calendarAppointments.appointments[i].client_contacts
      });
    }

    this.calendarAppointments.appointments = events;

    // Task events

    const taskEvents = [];
    for (let i = 0; i < calendarAppointments.tasks.length; i++) {
      taskEvents.push({
        id: calendarAppointments.tasks[i].id,
        name:
          calendarAppointments.tasks[i].category &&
          calendarAppointments.tasks[i].category[0] === 1
            ? i18n.t("reminder_label")
            : calendarAppointments.tasks[i].title,
        start: new Date(calendarAppointments.tasks[i].due_at),
        end: new Date(
          new Date(calendarAppointments.tasks[i].due_at).setMinutes(
            new Date(calendarAppointments.tasks[i].due_at).getMinutes() + 30
          )
        ),

        color: [3, 4, 5].includes(calendarAppointments.tasks[i].task_status_id)
          ? addOpacity("#2da1ff", 0.5)
          : "#2da1ff",

        details:
          (
            "0" + new Date(calendarAppointments.tasks[i].due_at).getHours()
          ).slice(-2) +
          ":" +
          (
            "0" + new Date(calendarAppointments.tasks[i].due_at).getMinutes()
          ).slice(-2),
        user: calendarAppointments.tasks[i].assignee_id,
        notes: calendarAppointments.tasks[i].notes,
        address: calendarAppointments.tasks[i].address?.split(",")[0],
        client_name: calendarAppointments.tasks[i].name,
        tag_id: calendarAppointments.tasks[i].tag_id?.split("_").pop(),
        area: calendarAppointments.tasks[i].area,
        installation_id: calendarAppointments.tasks[i].installation_id,
        timed: !calendarAppointments.tasks[i].all_day,
        assignee_id: calendarAppointments.tasks[i].assignee_id,
        assignee_ids: calendarAppointments.tasks[i].assignee_ids,
        assignees: calendarAppointments.tasks[i].assignees,
        category: calendarAppointments.tasks[i].assignee_id,
        isTask: true,
        all_day: calendarAppointments.tasks[i].all_day,
        action_type_id: calendarAppointments.tasks[i].action_type_id,
        due_at: calendarAppointments.tasks[i].due_at,
        task_status_id: calendarAppointments.tasks[i].task_status_id,
        installation_item_id:
          calendarAppointments.tasks[i].task_installation_item_id,
        is_orphan: calendarAppointments.tasks[i].installation_id ? false : true,
        task_category: calendarAppointments.tasks[i].category,
        task_item: calendarAppointments.tasks[i].task_item,
        item_type_text: calendarAppointments.tasks[i].item_type_text,
        item_make: calendarAppointments.tasks[i].item_make,
        item_model: calendarAppointments.tasks[i].item_model,
        item_serial_no: calendarAppointments.tasks[i].item_serial_no,
        installation_description: calendarAppointments.tasks[i].installation_description,
        contacts: calendarAppointments.tasks[i].client_contacts
      });
    }

    (this.calendarAppointments as any).tasks = taskEvents;

    this.calendarAppointments.appointments =
      this.calendarAppointments.appointments.concat(
        this.calendarAppointments.tasks as any
      );
  }

  @Mutation
  public clearCalendarAppointments(): void {
    this.calendarAppointments.appointments = [];
  }

  @Mutation
  public setIsLoading(isLoading: boolean): void {
    this.isLoading = isLoading;
  }

  @Mutation
  public setFilters(filters: string): void {
    this.filters = filters;
  }

  @Mutation
  public setCategories(): void {
    const params = new URLSearchParams(this.filters);
    const assigneeIds = params.getAll("assignee_id[]");

    let techniciansCombo = commonModule.techniciansCombo;
    let personnelCombo = commonModule.personnelCombo;

    if (assigneeIds.length > 0) {
      techniciansCombo = techniciansCombo.filter(
        (assignee: Record<string, any>) => assigneeIds.includes(assignee.id)
      );
      personnelCombo = personnelCombo.filter((assignee: Record<string, any>) =>
        assigneeIds.includes(assignee.id)
      );
    }

    if (localStorage.getItem("calendar_type") == "category") {
      this.categories = {
        name: techniciansCombo.map((item: any) => item.name),
        id: techniciansCombo.map((item: any) => item.id),
      };
    } else {
      this.categories = {
        name: personnelCombo.map((item: any) => item.name),
        id: personnelCombo.map((item: any) => item.id),
      };
    }
  }

  @Mutation
  public setIsLoadingIndicator(isLoadingIndicator: boolean): void {
    this.isLoadingIndicator = isLoadingIndicator;
  }

  @Mutation
  public setQueryObject(queryObject: any): void {
    this.queryObject = queryObject;
  }

  @Mutation
  public setCalendarSelectedType(calendarSelectedType: string): void {
    this.calendarSelectedType = calendarSelectedType;
  }

  @Mutation
  public setCalendarEndDate(calendarEndDate: any): void {
    this.calendarEndDate = calendarEndDate;
    this.calendarEndDate = new Date(calendarEndDate);
    this.calendarEndDate.setHours(23, 59, 59, 999);
  }

  @Mutation
  public setIsLoadingTable(isLoadingTable: boolean): void {
    this.isLoadingTable = isLoadingTable;
  }

  @Mutation
  public setAppointmentsFilter(appointmentsFilters: any): void {
    this.appointmentsFilters = appointmentsFilters;
  }

  @Mutation
  public setSearch(search: string): void {
    this.search = search;
  }

  @Mutation
  public unsetCalendarAppointments(): void {
    this.calendarAppointments.appointments = [];
  }

  @Mutation
  public setAddCalendarInstallation(installationId: string): void {
    this.addCalendarInstallation = installationId;
  }
}
