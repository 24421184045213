
  import { commonModule, companyModule } from '@/store/modules/store-accessor'
  import Vue from "vue";
  import { Component, Prop } from "vue-property-decorator";
  
  @Component
  export default class AssigneeMultiUserCombo extends Vue {
    selectedAssignees = [] as any;
    assigneeIdCombo = [] as any;
    allOptionsSelectedFlag = false;

    @Prop({ default: () => ({}) }) defaultAssignees: any[]; // for edit modals & add appointment in the calendar page
    @Prop({ default: true }) rightMargin!: boolean; // will set it to false in the add job modal
    @Prop({ default: true }) groupSelected!: boolean;
    @Prop({ default: true }) selectAll!: boolean;
    @Prop({ default: false }) isTask!: boolean;


    loading = true;

    selectEntry() {
      this.$emit('update-filter', this.selectedAssignees);
    }

    doSelectAll() {
      this.allOptionsSelectedFlag = !this.allOptionsSelectedFlag;
      if (this.allOptionsSelectedFlag) {
        this.selectedAssignees = this.assigneeIdCombo;
      }
      else {
        this.selectedAssignees = [];
      }
      this.$emit('update-filter', this.selectedAssignees);
    }

    async mounted() {
      if(!this.isTask) {
        await commonModule.getPersonnelCombo();
        this.assigneeIdCombo = commonModule.personnelCombo;
      } else {
        await commonModule.getPersonnelPartnersCombo();
        this.assigneeIdCombo = commonModule.personnelPartnersCombo;
      }

      if(this.defaultAssignees && Array.isArray(this.defaultAssignees)) {

        this.selectedAssignees = this.defaultAssignees.map((assignee) => {  
          let assignee_obj = {};

          const foundItem = this.assigneeIdCombo.find((item: any) => (item.name === assignee.name) && (item.id === assignee.id));

          if(!foundItem) {
            assignee_obj = {name: assignee?.name, id: assignee?.id, hidden_id: (Math.random() + 1).toString(36).substring(7)};
            this.assigneeIdCombo.unshift(assignee_obj)
          }
          else {
            assignee_obj = foundItem;
          }
          
          return assignee_obj;
        });

        this.$emit('update-filter', this.selectedAssignees);
      }

      if(!this.isTask && Object.keys(this.defaultAssignees).length == 0 && this.assigneeIdCombo.length === 1) {
        // Pre-select the only assignee when there is only one in the list
        this.allOptionsSelectedFlag = !this.allOptionsSelectedFlag;
        if (this.allOptionsSelectedFlag) {
          this.selectedAssignees = this.assigneeIdCombo;
        }
        this.$emit('update-filter', this.selectedAssignees);
      }

      this.loading = false;
    }

    rules = [
      (val: any) => (val.length > 0) || this.$i18n.t("user_required"),
    ]

    get assigneeDropdownLabel() {
      if(this.isTask && companyModule.company?.modules?.includes('DFB_PORTAL_PARTNERS')) {
        return this.$i18n.t('userPartner');
      }
      else {
        return this.$i18n.t('user');
      }
    }
  }
