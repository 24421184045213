import { VuexModule, Module, Action, Mutation } from 'vuex-module-decorators'
import CountriesApi from '@/api/country-api'

@Module({ namespaced: true, name: 'countries' })
export default class CountryModule extends VuexModule {
    public countries: string[] = [];

    @Action({ commit: 'setCountries' })
    async getCountriesAction(): Promise<string[]> {
        const countriesApi = new CountriesApi();
        const countries = await countriesApi.getAllCountries();

        return countries;
    }

    @Mutation
    public setCountries(countries: string[]): void {
        this.countries = countries;
    }
}