import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators'
import ComboApi from '@/api/combo-api'
import i18n from "@/i18n";
import { getDurationText } from "@/helpers/data";

@Module({ name: 'common'})
export default class CommonModule extends VuexModule {
    user: User = { name: "", email: "", uuid: ''};
    modal: ModalObject =  { name: '', payload: {} };
    secondaryModal: ModalObject = { name: '', payload: {} };
    controlAccess = false;
    sideview: SideviewObject = {name: '', payload: {} };
    snackbar: SnackbarObject =  { visible: false, color: "#000000", timeout: 2000, text: "test" }
    thirdModal: ModalObject = { name: '', payload: {} };

    public personnelCombo = [] as any[];
    public actionCombo = []
    public clientsCombo = []
    public fuelTypeCombo = []
    public contactTypeCombo = []
    public placementTypeCombo = []
    public itemTypeCombo = []
    public appointmentStatusCombo = []
    public itemsCombo = []
    public durationCombo = []
    public installationsCombo = []
    public paymentTypeCombo = []
    public isLoading = true;
    public techniciansCombo = []
    public personnelPartnersCombo = [] as any[]
    public periodicCombo = []
    public timeChargedUnitCombo = []


    @Action({ commit: 'setPersonnelCombo'})
    async getPersonnelCombo (): Promise<any[]> {
      const comboApi = new ComboApi()
      const personnelCombo = await comboApi.getPersonnelCombo();
      this.setIsLoading(false);
      return personnelCombo.map((x: any) => {
        x.hidden_id = (Math.random() + 1).toString(36).substring(7);
        return x
      });
    }
  
    @Action({ commit: 'setActionCombo'})
    async getActionCombo (): Promise<any[]> {
      const comboApi = new ComboApi()
      const actionCombo = await comboApi.getActionTypeCombo();
      this.setIsLoading(false);
      return actionCombo
    }

    @Action({ commit: 'setPaymentTypeCombo'})
    async getPaymentTypeCombo (): Promise<any[]> {
      const comboApi = new ComboApi()
      const paymentTypeCombo = await comboApi.getPaymentTypeCombo();
      return paymentTypeCombo
    }
  
    @Action({ commit: 'setClientsCombo'})
    async getClientsCombo (): Promise<any[]> {
      const comboApi = new ComboApi()
      const clientsCombo = await comboApi.getClientsCombo();
      this.setIsLoading(false);
      return clientsCombo
    }
  
    @Action({ commit: 'setFuelTypeCombo'})
    async getFuelTypeCombo (): Promise<any[]> {
      const comboApi = new ComboApi()
      const fuelTypeCombo = await comboApi.getFuelTypeCombo();
      this.setIsLoading(false);
      return fuelTypeCombo
    }
  
    @Action({ commit: 'setContactTypeCombo'})
    async getContactTypeCombo (): Promise<any[]> {
      const comboApi = new ComboApi()
      const contactTypeCombo = await comboApi.getContactTypeCombo();
      this.setIsLoading(false);
      return contactTypeCombo
    }
  
    @Action({ commit: 'setPlacementTypeCombo'})
    async getPlacementTypeCombo (): Promise<any[]> {
      const comboApi = new ComboApi()
      const placementTypeCombo = await comboApi.getPlacementTypeCombo();
      this.setIsLoading(false);
      return placementTypeCombo
    }
  
    @Action({ commit: 'setItemTypeCombo'})
    async getItemTypeCombo (): Promise<any[]> {
      const comboApi = new ComboApi()
      const itemTypeCombo = await comboApi.getItemTypeCombo();
      this.setIsLoading(false);
      return itemTypeCombo
    }
  
    @Action({ commit: 'setAppointmentStatusCombo'})
    async getAppointmentStatusCombo (): Promise<any[]> {
      const comboApi = new ComboApi()
      const appointmentStatusCombo = await comboApi.getAppointmentStatusCombo();
      this.setIsLoading(false);
      return appointmentStatusCombo
    }
  
    @Action({ commit: 'setDurationCombo'})
    getDurationCombo (): any {
      const durationCombo: any = [];
      const duration_minutes = [];
      const duration_text = [];

      const hoursInMinutes = 1440; // 24 hours

      for (let i = 30; i <= hoursInMinutes; i += 30) {
        duration_minutes.push(i);
        duration_text.push(getDurationText(i)); // convert to 30m, 1h, 1h 30m...
      }

      for(let i = 0; i < duration_minutes.length; i++) {
        durationCombo.push({
          "dur": duration_minutes[i],
          "text": duration_text[i]
        });
      }

      return durationCombo
    }

    @Action({ commit: 'setPeriodicCombo'})
    getPeriodicCombo (num: number): any {
      const periodicCombo: any = [
        { "period" : 1 , "text" : i18n.t("day_s") },
        { "period" : 7 , "text" : i18n.t("week_s") },
        { "period" : 30 , "text" : i18n.t("month_s")  },
        { "period" : 365 , "text" : i18n.t("Year_s") },
      ];
      return periodicCombo
    }

    @Action({ commit: 'setInstallationsCombo'})
    async getInstallationsCombo (): Promise<any[]> {
      const comboApi = new ComboApi()
      const installationsCombo = await comboApi.getInstallationsmCombo();
      return installationsCombo
    }

    @Action({ commit: 'setPersonnelPartnersCombo'})
    async getPersonnelPartnersCombo (): Promise<any[]> {
      const comboApi = new ComboApi()
      const personnelPartnersCombo = await comboApi.getPersonnelPartnersCombo();
      this.setIsLoading(false);

      return personnelPartnersCombo.map((x: any) => {
        x.hidden_id = (Math.random() + 1).toString(36).substring(7);
        return x
      });
    }

    @Action({ commit: 'setTimeChargedUnitCombo'})
    getTimeChargedUnitCombo(): any {
      return [
        { "unit" : 'HOUR' , 'text' : i18n.t('hour') },
        { "unit" : 'DAY' , 'text' : i18n.t('day') },
      ];
    }

    @Mutation
    public setPaymentTypeCombo (paymentTypeCombo: []): void {
      this.paymentTypeCombo = paymentTypeCombo
    }

    @Mutation
    public setInstallationsCombo (installationsCombo: []): void {
      this.installationsCombo = installationsCombo
    }

    @Mutation
    public setPersonnelCombo (personnel: []): void {
      this.techniciansCombo = personnel.filter((p: any) => p.roles.some((role: string) => role === "viewer"));
      this.personnelCombo = personnel
    }
  
    @Mutation
    public setActionCombo (action: []): void {
      this.actionCombo = action;
    }
  
    @Mutation
    public setClientsCombo (clients: []): void {
      this.clientsCombo = clients;
    }
  
    @Mutation
    public setFuelTypeCombo (fuelTypes: []): void {
      this.fuelTypeCombo = fuelTypes;
    }
  
    @Mutation
    public setContactTypeCombo (contactTypes: []): void {
      this.contactTypeCombo = contactTypes;
    }
  
    @Mutation
    public setPlacementTypeCombo (placementTypes: []): void {
      this.placementTypeCombo = placementTypes;
    }
  
    @Mutation
    public setItemTypeCombo (itemTypes: []): void {
      this.itemTypeCombo = itemTypes;
    }
  
    @Mutation
    public setAppointmentStatusCombo (appointmentStatus: []): void {
      this.appointmentStatusCombo = appointmentStatus;
    }

    @Mutation
    public setDurationCombo (durationCombo: []): void {
      this.durationCombo = durationCombo;
    }

    @Mutation
    public setPeriodicCombo (periodicCombo: []): void {
      this.periodicCombo = periodicCombo;
    }
    
    @Action({ commit: 'setInventoryItemCombo'})
    async getInventoryItemCombo (): Promise<any[]> {
      const comboApi = new ComboApi()
      const itemsCombo = await comboApi.getInventoryItemCombo();
      return itemsCombo
  }

  @Mutation
  public setInventoryItemCombo (action: []): void {
    this.itemsCombo = action
  }

    @Mutation
    showModal(modal: any) {
      this.modal = modal
    }

    @Mutation
    hideModal() {
      this.modal = { name: '', payload: {} }
    }

    @Mutation
    showSecondModal(secondModal: any) {
      this.secondaryModal = secondModal
    }

    @Mutation
    hideSecondModal() {
      this.secondaryModal = { name: '', payload: {} }
    }

    @Mutation
    initSnackbar(snackbar: any) {
      this.snackbar = snackbar
    }

    @Mutation
    hideSnackbar() {
      this.snackbar = { visible: false, color: "#ffffff", timeout: 200, text: "" }
    }

    @Mutation
    showSideview(sideview: any) {
      this.sideview = sideview
    }

    @Mutation
    hideSideview() {
      this.sideview = { name: '', payload: {} }
    }

    @Mutation
    setUserInfo(user: Record<string, any>) {
      this.user.name = user.given_name;
      this.user.email = user.email;
      this.user.uuid = user.sub;
    }

    @Mutation
    setControlAccess(controlAccess: boolean) {
      this.controlAccess = controlAccess;
    }

    @Mutation
    public setIsLoading(isLoading: boolean): void {
      this.isLoading = isLoading
    }

    @Mutation
    public setPersonnelPartnersCombo (personnelPartnersCombo: []): void {
      this.personnelPartnersCombo = personnelPartnersCombo
    }

    @Mutation
    public setTimeChargedUnitCombo (timeChargedUnitCombo: []): void {
      this.timeChargedUnitCombo = timeChargedUnitCombo;
    }

    @Mutation
    showThirdModal(thirdModal: any) {
      this.thirdModal = thirdModal
    }

    @Mutation
    hideThirdModal() {
      this.thirdModal = { name: '', payload: {} }
    }

}
