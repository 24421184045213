
import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import { installationsModule, clientsModule, companyModule, commonModule, itemsModule, appointmentsModule, tasksModule, countriesModule } from '@/store/modules/store-accessor'
import MapAddressCoordinates from '@/components/Map/MapAddressCoordinates.vue'

@Component({
  components: {
   MapAddressCoordinates
  }
})
export default class QuickAddCalendarInstallation extends Vue {
    @Prop() readonly step: number;
    @Prop() readonly currentStep: any;
    @Prop() readonly client: any;
    @Prop() readonly payload!: Record<string, any>;

    selectedInstallation = {};
    clientInstallations = [];
    addAppointment = true;
    addInstallationLoading = false;
    newInstallationFlag = false;
    isLoading = true;
    markerCoords = [37.983810, 23.727539];
    lat = this.markerCoords[0];
    long = this.markerCoords[1];

    installationModel = {
        tag: this.installationNextTagId,
        client: { name: "", id: 0 },
        owner: { name: "", id: 0 },
        address: "",
        area: "",
        zip_code: "",
        floor: "",
        is_premium: false,
        notes: "",
        latitude: "",
        longitude: "",
        description: '',
        selected_item: { id: 0, name: '', item_type_id: 0, warranty: '', is_primary: false, is_active: false, serial_no: '' },
    }

    tagRule = [
        (val: any) => !!val || this.$i18n.t("tag_required"),
    ]

    clientRule = [
        (val: any) => ((!!val || val == '') && val.name.length > 0) || this.$i18n.t("tenant_required"),
    ]

    areaRule = [
        (val: any) => !!val || this.$i18n.t("area_required"),
        (val: any) => val.length <= 30 || this.$i18n.t("form_area_length_rule"),
    ]

    addressRule = [
        (val: any) => val?.length <= 100 || this.$i18n.t("form_address_length_rule"),
    ]

    zipCodeRule = [
        (val: any) => val?.length <= 15 || this.$i18n.t("form_zipCode_length_rule"),
    ]

    notesRule = [
        (val: any) => val.length <= 1000 || this.$i18n.t("notesLengthRule"),
    ]

    installationRule = [
        (val: any) => ((!!val || val == '') && val.id > 0) || this.$i18n.t("installation_required"),
    ]

    descriptionRule = [
        (val: any) => val.length <= 80 || this.$i18n.t("installationDescriptionLengthRule"),
    ]

    serialNoRule = [
        (val: any) => {
            if (val === undefined || val === null) {
                return true;
            }
            return val.length <= 50 || this.$i18n.t("form_serialNumber_length_rule");
        },
    ];

    @Watch('currentStep')
    async stepUpdate() {
        if (this.currentStep == this.step) {
            await installationsModule.getInstallationNextTagId();
            await companyModule.getCompanyInfo();
            await itemsModule.getAllInventoryItems('');
            await countriesModule.getCountriesAction();
            this.installationModel = {
                tag: this.installationNextTagId,
                client: { name: "", id: 0 },
                owner: { name: "", id: 0 },
                address: "",
                area: "",
                zip_code: "",
                floor: "",
                is_premium: false,
                notes: "",
                latitude: "",
                longitude: "",
                description: '',
                selected_item: { id: 0, name: '', item_type_id: 0, warranty: '', is_primary: false, is_active: false, serial_no: '' },
            }
            if (this.client.id != -1) {
                this.isLoading = true
                this.clientInstallations = await clientsModule.getClientInstallations(this.client.id.toString())

                if (this.clientInstallations.length === 0) {
                    this.newInstallationFlag = true;
                }

                this.isLoading = false
            }
        }
    }

    get installationsCombo () {
        let installations = this.clientInstallations.map((item: any) => {
            let address_item = item.formatted_address.trim()
            address_item += (item.floor) ? ' ' + this.$i18n.t("floor") + ' ' + item.floor : ''
            return {...item, full_address: address_item}
        });

        if (installations.length == 1)
            this.selectedInstallation = installations[0];

        return installations;
    }

    get showInstallationsCombo () {             
        return this.installationsCombo.length > 0
    }

    get showNewInstallationForm () {
        return !this.isLoading && this.newInstallationFlag;
    }

    get installationNextTagId() {
        return installationsModule.installationNextTagId;
    }

    get isLoadingCombo() {
        return this.isLoading;
    }

    get installationItemsCombo() {
        return itemsModule.installationItems;
    }

    getAddressData(addressData: any) {
      this.installationModel.area = addressData?.locality ?? '';
      this.installationModel.zip_code = addressData.postal_code ? addressData.postal_code.replace(/\s/g, "") : '';
      this.installationModel.latitude = addressData?.latitude;
      this.installationModel.longitude = addressData?.longitude;

      this.markerCoords = [parseFloat(this.installationModel?.latitude), parseFloat(this.installationModel?.longitude)];
      this.lat = this.markerCoords[0];
      this.long = this.markerCoords[1];
    }

    updateAddressField(addressInfo: any) {
      this.installationModel.address = addressInfo.address;
      this.installationModel.area = addressInfo.area;
      this.installationModel.zip_code = addressInfo.zip_code;
      this.installationModel.latitude = addressInfo.latitude;
      this.installationModel.longitude = addressInfo.longitude;
    }

    isNumber(evt: any) {
        evt = evt || window.event;
        let charCode = evt.which || evt.keyCode;
        if (charCode > 31 && (charCode < 48 || charCode > 57)) {
            evt.preventDefault();
        } else {
            return true;
        }
        return false;
    }

    async endStep() {
        if ((this.$refs.form as Vue & { validate: () => boolean }).validate()) {
            this.installationModel.client = this.client;

            this.addInstallationLoading = true;

            if (this.newInstallationFlag) {
                // Installation Item
                if (this.installationModel.selected_item.item_type_id) {
                    this.installationModel.selected_item.is_primary = true;
                    this.installationModel.selected_item.is_active = true;
                }

                const installationCreated = await installationsModule.addInstallation(this.installationModel);
                installationsModule.setIsLoadingTable(true);
                installationsModule.getInstallationsAction('');
                this.selectedInstallation = installationCreated;
                await commonModule.getInstallationsCombo();
            }

            if(this.payload && this.payload.isOrphanTask) {
                tasksModule.setAddOrphanTaskInstallation((this.selectedInstallation as any).id);
            } else {
                appointmentsModule.setAddCalendarInstallation((this.selectedInstallation as any).id);
            }

            await itemsModule.getInstallationPrimaryInventoryItems((this.selectedInstallation as any).id);

            this.$emit('close-modal')

        }
    }

    prevStep() {
        this.$emit('prev-step')
        this.isLoading = true;
        this.addInstallationLoading = false;
        this.addInstallationLoading = false;
    }

    get descriptionIsEnabled() {
        return (companyModule.company?.preferences as any)?.installation_description;
    }

    get installationCooridnates() {
        return installationsModule.newInstallationCoordinates;
    }

    get countries() {
        return countriesModule.countries;
    }
}
