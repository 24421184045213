
import { Component, Vue, Prop } from 'vue-property-decorator'
import { commonModule, tasksModule, actionTypesModule, companyModule } from '@/store/modules/store-accessor'
import { TimeUtil } from '@/helpers/TimeUtil'
import { getOptimalTextColor, taskStatusToColor, taskStatusToName, templatesFilenameLanguage } from "@/helpers/data";
import _ from 'lodash';
import FileSaver from 'file-saver';
import dayjs from 'dayjs';
import SnoozeTask from "@/components/Snooze/SnoozeTask.vue";

@Component({
  components: {
    SnoozeTask
  },
})
export default class openOrphanTaskModal extends Vue {
  @Prop()
  payload!: any;
  isEditingTask = false;
  isDownloadingTaskPdf = false;

  get task() {
    return tasksModule.currentTask;
  }

  optimalTextColor(color: string) {
    if(color) {
      return getOptimalTextColor(color);
    }
    return 'white';
  }

  async hideModal() {
    commonModule.hideModal();
    tasksModule.unsetCurrentTask();
  }

  formatDateTime (date: Date): string {
    return TimeUtil.formatDateTime(date)
  }


  getAssigneeId(uuid: string) {
    const combo: any = commonModule.personnelPartnersCombo;
    const foundItem = combo.find((item: any) => item.id === uuid);
    if(foundItem) {
      return foundItem.name;
    } else {
      return ''
    }
  }

  async beforeMount() {
    actionTypesModule.getActionTypesCombo();
  }

  async mounted() {
    commonModule.initSnackbar({});
    commonModule.getPersonnelCombo();
  }

  formatDate (date: Date) {
    if (date == undefined || date == null) {
      return '-'
    } else {
      return TimeUtil.formatDate(date)
    }
  }

  setStatusColor(status: number) {
    return taskStatusToColor(status);
  }

  setStatusText(status: number) {
    return taskStatusToName(status);
  }

  completeTask (){
    commonModule.showSecondModal({ name: 'complete-task-modal', payload: { task: this.task, isOrphanTask: true}});
  }

  editTask (){
    commonModule.showSecondModal({ name: 'edit-task-modal', payload: { task: this.task, isOrphanTask: true}});
  }

  deleteTask (task: any){
    commonModule.showSecondModal({ name: 'delete-task-modal', payload: { task: task, isOrphanTask: true}})
  }

  async donwloadTaskPdf(task: Record<string, unknown>) {
    this.isDownloadingTaskPdf = true;
    const utcOffset = dayjs().utcOffset();

    const data = { 'taskId': task.id, 'utcOffset': utcOffset, language: localStorage.getItem('lang') };

    if(task.id) {
      const company = await companyModule.getCompanyInfo();
      const filename = `${templatesFilenameLanguage()}_${company.entity_name}_${task.id}.pdf`.replace(/ /g, '_');
      const pdf = await  tasksModule.getTaskPdfExportAction(data);
      const file = new File([pdf], filename);
      FileSaver.saveAs(file);
    }
    this.isDownloadingTaskPdf = false;
  }
};
